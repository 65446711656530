/* About Me Section */

.about-section {
    display: flex;
    flex-direction: row;
    

}

.about-section-part {
    padding: 0 px
}

.spacing {
    padding: 8px 8px 8px 8px;
}



/* Project Section */
.project-link {
    color: #000000;
    text-decoration: none;
}

.project-div{
    display: flex;
    flex-direction: row;
    padding: 12px;
}
.project-div:hover {
    background-color: rgba(224, 224, 224, 0.5);
}

/* Navbar */
.nav-anchor {
    text-decoration: none;
    color: #000000;
}